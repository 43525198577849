import React from 'react';
import { useState, useEffect } from 'react';
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import { getParametersData, setParametersData } from '../services/user.service';
import { customSort } from '../functions/sort.function';
import { useLocation, useParams } from 'react-router-dom';

registerLocale('es', es)



function getThresholdRange(thresholds, i) {
  const lower = thresholds[`threshold_level${i}_lower`];
  const upper = thresholds[`threshold_level${i}_upper`];
  return `[${lower}, ${upper}]`;
}

function getThresholdString(thresholds) {
  const thresholdStrs = [];

  for (let i = 1; i <= Object.keys(thresholds).length / 2; i++) {
    thresholdStrs.push(`Umbral ${i} ${getThresholdRange(thresholds, i)}`);
  }

  return thresholdStrs.join(', ');
}

function parByProd(data, parpage) {
  if (parpage === 'lastprice') {
  return data.reduce((map, obj) => {
    const prod = obj.product;

    if (!map[prod]) {
      map[prod] = { size: new Map(), spread: new Map() };
    }

    map[prod].size.set(obj.dtime, obj.minsize_q25);
    map[prod].spread.set(obj.dtime, obj.maxspread_q75);

    return map;
  }, {});

}
  else if (parpage === 'fastmarket')
  {
    return data.reduce((map, obj) => {
      const prod = obj.product;
  
      if (!map[prod]) {
        map[prod] = { spread: new Map(), threshold: new Map(),  };
      }
  
      map[prod].spread.set(obj.dtime, {
        maxspread_q75: obj.maxspread_q75,
        maxspread_q90: obj.maxspread_q90});
      map[prod].threshold.set(obj.dtime, {
        threshold_level1_lower: obj.threshold_level1_lower,
        threshold_level1_upper: obj.threshold_level1_upper,
        threshold_level2_lower: obj.threshold_level2_lower,
        threshold_level2_upper: obj.threshold_level2_upper,
        threshold_level3_lower: obj.threshold_level3_lower,
        threshold_level3_upper: obj.threshold_level3_upper,
        threshold_level4_lower: obj.threshold_level4_lower,
        threshold_level4_upper: obj.threshold_level4_upper});
  
      return map;
    }, {});
  }
}

function curParByProd(data) {
  return data.reduce((map, obj) => {
    const prod = obj.product;

    if (!map[prod]) {
      map[prod] = new Map();
    }
    map[prod].set(obj.mibparamtype,obj.mibparamdate)
    return map;
  }, {});
}

function Parameters() {
    const [data, setData] = useState(new Map());
    const [currentParams, setCurrentParams] = useState(new Map())
    const [paramSelection, setParamsSelection] = useState(new Map())
    let { parpage } = useParams();
    let location = useLocation();

    
  
    const refresh = () => {
      getParametersData(parpage)
        .then(res => {
          let dbdata = JSON.parse(res.data)
          let organizedparams = parByProd(dbdata.params.sort((a, b) => customSort(a.product, b.product)), parpage)
          let currparams = curParByProd(dbdata.currentparams)
          setData(organizedparams)
          setCurrentParams(currparams)
          setParamsSelection(curParByProd(dbdata.currentparams))
        })
        .catch(err => console.log(err));
    }
  
    useEffect(() => {
      refresh()
    }, [location, parpage]);

  const Dropmedown = (onChange, product, partype) => {
    const datapartype = partype.split(':')[1]
    let options2 = new Map()
    if (partype === 'fastmarket:threshold') {
      data?.[product]?.[datapartype]?.forEach((vv, pardate) => {

        options2.set(pardate, getThresholdString(vv))
      });
    }
    else if (partype === 'fastmarket:spread') {
      data?.[product]?.[datapartype]?.forEach((vv, pardate) => {

        options2.set(pardate, `Spread [${vv.maxspread_q75}, ${vv.maxspread_q90}]`)
      });
    }
    else {
      options2 = data?.[product]?.[datapartype]
      if (options2 && partype === 'lastprice:spread' &&
        typeof [...options2?.entries()] === 'object' && 
        [...options2?.entries()][0][1]?.hasOwnProperty('maxspread_q90')){
          options2 = undefined
      }
    }
    if (options2) {
      let msdfs = data?.[product]?.[datapartype]?.has(paramSelection?.[product]?.get(partype)) ?? false
      let paramdate = paramSelection?.[product]?.get(partype) ?? ''
      return (
        <div>
          <select className='overflow-hidden w-[12rem]' value={paramdate} onChange={(e) => onChange(e, product, partype)}>
            {msdfs ? '' : <option value="" selected disabled hidden>Set</option>}
            {
              [...options2?.entries()].map(([key, val]) => (
                <option value={key}>
                  {key}: {val}
                </option>
              ))
            }
          </select></div>
      );
    }

  };
  
    const handleDropdownChange = (event, product, partype) => {
      let nuparsel = {...paramSelection}
      if (!nuparsel[product]) {
        nuparsel[product] = new Map();
      }
      nuparsel[product].set(partype, event.target.value)
      setParamsSelection(nuparsel)
    };
  
  
    const compareArrays = (original, currentData) => {
      const result = [];
      Object.keys(currentData).forEach(product => {
        let prodData = currentData[product]
        prodData.forEach((vv,partype) => {
          if (vv !== original?.[product]?.get(partype)) {
            const updaterobj = {product: product, mibparamtype:partype, mibparamdate:vv };
            result.push(updaterobj)
          }
        })
      })
      return result;
    }
    const getParColumnsVals = (product, partype ) => {
      const datapartype = partype.split(':')[1]
      const whatever = data?.[product]?.[datapartype]?.get(paramSelection?.[product]?.get(partype))

      let cols = []
      if (!whatever)
      {
        if (partype === 'fastmarket:threshold'){
          
          cols =Array.from({ length: 4 }, (_, i) => <td key={`${product}${partype}${i}`} />);
        }
        else{
          cols = [<td key={`${product}${partype}`} />]
        }

      }
      else{

        if (partype === 'fastmarket:threshold')
        {
          cols =Array.from({ length: 4 }, (_, i) => <td className="p-2" key={`${product}${partype}${i}`}> {getThresholdRange(whatever, i+1)}</td>)
        }
        else if (partype === 'fastmarket:spread')
        {
          cols = [<td key={`${product}${partype}`}> {`[${whatever.maxspread_q75}, ${whatever.maxspread_q90}]`} </td>]
        }
        else {
          cols = [<td key={`${product}${partype}`}> {whatever} </td>]
        }
      }
      return (<>{cols}</>)
    }
  
    const handleClick = ()=> {
      let valueUpdater = compareArrays(currentParams, paramSelection);
      setParametersData(valueUpdater).then(response => {
        refresh();
      })
      .catch(error => {
        console.log(error);
        refresh();
      });
      
    }
  

  const parpage2title= {'fastmarket':'Fast Market','lastprice':'Last Price'}

  const parpage2cols={'fastmarket':['Producto', 'Fecha umbrales', 'Umbral 1', 'Umbral 2', 'Umbral 3', 
                                    'Umbral 4', 'Fecha Spread', 'Spread [Q75, Q90]' ],
                                  'lastprice':['Producto', 'Fecha Cantidad', 'Cantidad Q25', 'Fecha Spread', 'Spread Q75', ]}

  const parpage2types = {'fastmarket': {'fastmarket:threshold':'threshold', 'fastmarket:spread':'spread'}, 
  'lastprice':{'lastprice:size':'size', 'lastprice:spread':'spread'}}

 
    return (
  
      <div className='grow min-w-full place-content-center'>
        <div className="font-medium leading-tight text-3xl mt-0 mb-2 text-indigo-900" ><h3>Parámetros {`${parpage2title[parpage]}`}</h3></div>
  
        <button className="bg-emerald-500 rounded-lg py-2 px-10 text-white mt-10" onClick={() => handleClick()}>Actualizar Parámetros</button> 
  
        <table className="table-auto mt-10 mx-auto">
          <thead>
            <tr className="bg-cyan-500 text-white sticky top-0">
              {parpage2cols[parpage].map((key) => <th className="text-center p-2" key={key}>{key}</th>)}
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map((key, index) => (
              <tr key={'parammrow'+key} className={(index % 2) === 0 ? "" : "bg-blue-100"}>
                <td className="p-2">{key}</td>
                {Object.entries(parpage2types[parpage]).map( ([it,iit]) =>  (
                  <>
                  <td className="p-2"> {Dropmedown(handleDropdownChange, key, it)}</td>
                  {getParColumnsVals(key, it)}
                  </> 
                  ))}
                </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  
  export default Parameters;