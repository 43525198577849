import React, { useState } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { isEmail } from "validator";
import {register} from "../services/auth.service";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = value => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = value => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = value => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const Register = () => {
  const [username, setUsername] = useState("");
  const [emailT, setEmailT] = useState("");
  const [password, setPassword] = useState("");
  
  const [message, setMessage] = useState("");
  const [successful, setSuccessful] = useState(false);
  
  const handleRegister = e => {
    e.preventDefault();
    setMessage("");
    setSuccessful(false);

    let email_valid = email(emailT);
    let user_valid = vusername(username);
    let pass_valid = vpassword(password);

    if (!email_valid && !user_valid && !pass_valid){
      register(username, emailT, password).then(response => {
        setMessage(response.data.message);
        setSuccessful(true);
      }, error => {
        const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        setSuccessful(false);
        setMessage(resMessage);
      });
    }
  };

  return <div className="col-md-12 place-content-center">
          <div className="font-medium leading-tight text-3xl mt-0 mb-2 text-indigo-900" ><h3>Register User</h3></div>
        <div className="card card-container bg-blue-100 mx-auto">

          <Form onSubmit={handleRegister}>
            {!successful && <div>
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <Input type="text" className="form-control" name="username" value={username} onChange={e => setUsername(e.target.value)} validations={[required, vusername]} />
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Input type="text" className="form-control" name="email" value={emailT} onChange={e => setEmailT(e.target.value)} validations={[required, email]} />
                </div>

                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <Input type="password" className="form-control" name="password" value={password} onChange={e => setPassword(e.target.value)} validations={[required, vpassword]} />
                </div>

                <div className="form-group">
                  <button className="bg-emerald-500 rounded-lg py-2 px-10 text-white mt-10">Register User</button>
                </div>
              </div>}

            {message && <div className="form-group">
                <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                  {message}
                </div>
              </div>}

          </Form>
        </div>
      </div>;
};

export default Register;