import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `https://${window.location.host}/okapi/mibui/`;

const getPublicContent = () => {
  return axios.get(API_URL + 'all');  
}

const getUserBoard = () => {
  return axios.get(API_URL + 'lp', { headers: authHeader() });
}

const getFastMarketBoard = () => {
  return axios.get(API_URL + 'fm', { headers: authHeader() });
}

const getModeratorBoard = () => {
  return axios.get(API_URL + 'mod', { headers: authHeader() });
}

const getAdminBoard = () => {
  return axios.get(API_URL + 'admin', { headers: authHeader() });
}

const getFastMarketData = (date) => {

  return axios.get(API_URL + `fm/${date.toISOString()}`, { headers: authHeader() });
}

const getPlotData = (date, products) => {
  let plotDataRequest = JSON.stringify({date: date.toISOString(), products: products})
  return axios.get(API_URL + `fmplot/${plotDataRequest}`, { headers: authHeader() });
}

const getParametersData = (service) => {
  return axios.get(API_URL + `par/${service}`, { headers: authHeader() });
}

const getDefaultParametersData = (service) => {
  return axios.get(API_URL + `defpar/${service}`, { headers: authHeader() });
}

const setDefaultParametersData = (rows) => {
  return axios.post(API_URL + 'defpar', {rows}, { headers: authHeader() } );
}

const setParametersData = (rows) => {
  return axios.post(API_URL + 'par', {rows}, { headers: authHeader() } );
}

const launchParameters = (date, modes) => {
  let paramRequest = {"datestr": date.toISOString().split('T')[0], "calculateparams4": modes}
  return axios.post(API_URL + 'par/calc', {paramRequest}, { headers: authHeader() } );
}

const sendMibgas = (date) => {
  let paramRequest = {"datestr": date.toISOString().split('T')[0]}
  return axios.post(API_URL + 'stt/mib', {paramRequest}, { headers: authHeader() } );
}

const reRunProcess = (date) => {
  let paramRequest = {"datestr": date.toISOString().split('T')[0]}
  return axios.post(API_URL + 'stt/rerun', {paramRequest}, { headers: authHeader() } );
}

const reRunExcel = (date) => {
  let paramRequest = {"datestr": date.toISOString().split('T')[0]}
  return axios.post(API_URL + 'stt/rerunExcel', {paramRequest}, { headers: authHeader() } );
}

const getSettleData = (date) => {
  return axios.get(API_URL + `stt/${date.toISOString().split('T')[0]}`, { headers: authHeader() });

}

const setSettleData = (rows) => {
  return axios.patch(API_URL + 'stt', {rows}, { headers: authHeader() } );
}

export {
  getPublicContent,
  getAdminBoard,
  getModeratorBoard,
  getUserBoard,
  getFastMarketBoard,
  getFastMarketData,
  getParametersData,
  setParametersData,
  getDefaultParametersData,
  setDefaultParametersData,
  launchParameters,
  sendMibgas,
  getPlotData,
  getSettleData,
  setSettleData,
  reRunProcess,
  reRunExcel
}