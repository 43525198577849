import React from 'react';
import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import { getFastMarketData, getPlotData } from '../services/user.service';
import Plotter from './plotter.component';
import { dataByObkey } from '../common/extra';

registerLocale('es', es)

const Fast_Market_color = ' bg-amber-800 text-white'
const SMC_color = ' bg-orange-600 text-white'
const warning_color = ' bg-lime-300 text-white'
const normal_color = ' bg-green-600 text-white'
const recomendacion_dict = {'Normal':normal_color, 'SMC':SMC_color, 'FM':Fast_Market_color, 'Warning': warning_color}





function FastMarket() {
  const [quotes, setQuotes] = useState(new Map());
  const [trades, setTrades] = useState(new Map());
  const [databyProd, setDatabyProd] = useState(new Map());
  const [startDate, setStartDate] = useState(new Date());



  const refresh = (mydate) => {
    let fmbyProds;
    let products;
    getFastMarketData(mydate)
      .then(res => {
        fmbyProds = dataByObkey(res.data, 'product');
        products = [...fmbyProds.keys()]

        if ((products.length > 0)) {
          getPlotData(mydate, products).then(res => {
            let rawdata = JSON.parse(res.data)
            let nuquotes = dataByObkey(rawdata.quotes, 'instrument', rawdata.replacement)
            let nutrades = dataByObkey(rawdata.trades, 'instrument', rawdata.replacement)
            setDatabyProd(fmbyProds)
            setQuotes(nuquotes);
            setTrades(nutrades);
          })
            .catch(err => console.log(err));
        } else {
          setDatabyProd(new Map());
          setQuotes(new Map());
          setTrades(new Map());
        }
      })
      .catch(err => console.log(err));
  }

  useEffect(() => {
    refresh(startDate)
    let interval;
    if ((new Date(startDate)).setHours(0,0,0,0) === (new Date()).setHours(0,0,0,0)){
      interval = setInterval(() => {
        setStartDate(new Date())
      }, 60000);
    }

    return () => {
      clearInterval(interval);
    };


  }, [startDate]);


  const handleDate = (date,) => {
    setStartDate(date);
    refresh(date)
  }

  const columnNames = {
    'Fecha': 'dtime',
    'Instrumento negociado': 'product',
    'Hora Inicial Criterio 1': 'criterion1_start',
    'Hora Inicial Criterio 2': 'criterion2_start',
    'Hora Final': 'dtime',
    'Criterio 1': 'criterion1',
    'Criterio 2': 'criterion2',
    'Umbral Inferior': 'threshold_lower',
    'Umbral Superior': 'threshold_upper',
    'Valor Criterio 1': 'criterion1_value',
    'Spread Inferior': 'maxspread_q75',
    'Spread Superior': 'maxspread_q90',
    'Valor Inf Criterio 2': 'criterion2_lower_value',
    'Valor Sup Criterio 2': 'criterion2_upper_value',
    'Criterio Instantáneo': 'criterion',
    'Recomendación': 'market_conditions'
  }

  const hours = ['Hora Inicial Criterio 1', 'Hora Inicial Criterio 2', 'Hora Final']
  const percent = ['Valor Inf Criterio 2', 'Valor Sup Criterio 2']


  return (

    <div className='grow min-w-full'>
      <div className="font-medium leading-tight text-3xl mt-0 mb-2 text-indigo-900" ><h3>Fast Market</h3></div>
      <DatePicker locale="es" dateFormat="dd/MM/yyyy HH:mm" showTimeInput timeCaption={null} selected={startDate} onChange={handleDate} />

      {/* <button className="bg-emerald-500 rounded-lg py-2 px-10 text-white mt-10" onClick={() => handleClick()}>Modify Settles</button> */}

      <div>
        {(databyProd.size === 0) ? <div className="font-medium leading-tight text-xl mt-10 mb-2 text-indigo-900" ><h5>No data for this period</h5></div> : null}
        {[...databyProd.entries()].map(([prodkey, data]) => {
          console.log(prodkey)
          return (
            <div className="mt-10">
              <div className="font-medium leading-tight text-2xl mt-0 mb-2 text-indigo-900" ><h4>{prodkey}</h4></div>
              <div className="max-h-[20rem] overflow-y-auto">
              <table className="table-auto mt-10">
                <thead>
                  <tr key = "fmheader" className="bg-cyan-500 text-white sticky top-0">
                    {Object.keys(columnNames).map((key) => <th className="text-center p-2" key={key}>{key}</th>)}
                  </tr>
                </thead>
                <tbody className ="overflow-auto max-h-20">
                  {data.map((row, index) => (
                    <tr key={'fmrow' + index} className={(index % 2) === 0 ? "" : "bg-blue-100"}>
                      {Object.keys(columnNames).map((colName) => {
                        if (colName === 'Fecha') {
                          let date = new Date(row[columnNames[colName]])
                          return <td className="p-2">{date.toLocaleString('es-ES', { year: '2-digit', month: '2-digit', day: '2-digit', hour12: false })}</td>
                        }
                        else if (hours.includes(colName)) {
                          let date = new Date(row[columnNames[colName]])
                          return <td className="p-2">{date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit', hour12: false })}</td>
                        }
                        else if (percent.includes(colName)) {
                          return <td className="p-2">{row[columnNames[colName]]} %</td>
                        }
                        else if (colName === 'Recomendación'){
                          let val = row[columnNames[colName]]
                          let classname = "p-2 "
                          if (recomendacion_dict[val]){
                            classname += recomendacion_dict[val]
                          }

                          return <td className={classname}>{val}</td>
                        }
                        else {
                          return <td className="p-2">{row[columnNames[colName]]}</td>
                        }
                      })
                      }


                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
                    
              <div className='mt-10'>
                <Plotter data={data} quotes={quotes.get(prodkey) ?? []} trades={trades.get(prodkey) ?? []} />
              </div>
            </div>)
        })}

      </div>
    </div>
  );
}

export default FastMarket;